import styled from "styled-components";
import {Link} from "react-router-dom";

interface ButtonProps {
    hidden?: boolean,
    border?: string,
    backgroundColor?: string,
    borderRadius?: string,
    margin?: string,
    padding?: string,
    gap?: string,
    width?: string
}

interface HiddenButtonProps {
    height?: string,
    width?: string,
    margin?: string,
    padding?: string,
    display?: string,
    lineHeight?: string
}


export const Button = styled.div<ButtonProps>`
    background: none;
    border: ${props => props.border ? props.border : `0.25rem solid ${props.theme.borderColor}`};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '0.50rem'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.secondary };
    margin: ${props => props.margin ? props.margin : ''};
    padding: ${props => props.padding ? props.padding : '1rem'};
    gap: ${props => props.gap ? props.gap : '0'};
    display: ${props => props.hidden ? 'none' : 'flex' };
    width: ${props => props.width ? props.width : '100%'};
    align-items: center;
    justify-content: center;
    transition: ${({theme}) => theme.transition};
    color: ${({theme}) => theme.text};
    cursor: pointer;


    &:hover {
        opacity: 0.7;
    }
`;

export const ButtonLink = styled(Link)<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: ${props => props.border ? "1px solid #545454" : 'none'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '0'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : ''};
    margin: ${props => props.margin ? props.margin : ''};
    padding: ${props => props.padding ? props.padding : ''};
    gap: ${props => props.gap ? props.gap : '0'};
    transition: ${({theme}) => theme.transition};
    cursor: pointer;


    &:hover {
        opacity: 0.7;
    }
`;

export const HiddenButton = styled.span<HiddenButtonProps>`
    cursor: pointer;
    line-height: ${props => props.lineHeight ? props.lineHeight : ''};
    height: ${props => props.height ? props.height : ''};
    width: ${props => props.width ? props.width : ''};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '0'};
    transition: ${({theme}) => theme.transition};

    &:hover {
        opacity: 0.5;
    }
`;

