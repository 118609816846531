import styled from "styled-components";

interface InputContainerProps {
    title: string;
    inputType: string;
    value: string | null;
    options?: string[] | undefined;
    onTextChange: (value: string) => void;
    required?: boolean | undefined;
}

const InputContainer: React.FC<InputContainerProps> = ({ title, inputType, value, options, onTextChange, required }) => (
    <InputWrapper>
        <InputLabel>{title} {required && <RedText>*</RedText>}</InputLabel>
        {inputType === "radio" && options && (
          options.map((item, index) => (
            <RadioItem>
              <RadioInput
                id={item.toLowerCase()}
                key={index}
                type={inputType}
                value={item}
                name={title.toLowerCase()}
                onChange={e => onTextChange(e.target.value)}
                defaultChecked={index === 0}
                />
              <RadioLabel htmlFor={item.toLowerCase()}>{item}</RadioLabel>
            </RadioItem>
          ))
        )}
        {inputType === "textarea" &&
          <StyledTextarea cols={4} value={value || ''}  onChange={e => onTextChange(e.target.value)}/>}
        {inputType !== "textarea" && inputType !== "radio" &&
          <StyledInput type={inputType} value={value || ''} onChange={e => onTextChange(e.target.value)}/>}
    </InputWrapper>
);

export default InputContainer;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.2rem; /* Reduced gap */
`;

const InputLabel = styled.label`
  font-size: 22px;
  color: #C7C7C7;
  font-weight: lighter;
`;

const RadioLabel = styled.label`
  font-size: 1.3em;
  color: #C7C7C7;
  font-weight: lighter;
  margin: 0.3rem 0.4rem;
`;

const RadioInput = styled.input`
  margin-left: 1em;
`;

const RadioItem = styled.div`
`;

const StyledInput = styled.input`
  font-size: 1.2em; /* Make the input text larger */
  font-family: inherit;
  padding: 0.5em 0.5em;
  width: 100%;
  background-color: transparent;
  color: #C7C7C7;
  border: 1px solid rgba(199, 199, 199, 0.6); 
  border-radius: 10px;
  height: 3rem;
  outline: none; /* Remove default outline */
  margin-top: 0.2rem; /* Reduced margin-top */

  &:focus {
      border-color: white;
      box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
  }
`;

const StyledTextarea = styled.textarea`
  font-size: 1.2em; /* Make the input text larger */
  font-family: inherit;
  padding: 0.5em 0.5em;
  width: 100%;
  background-color: transparent;
  color: #C7C7C7;
  border: 1px solid rgba(199, 199, 199, 0.6); 
  border-radius: 10px;
  height: 6rem;
  max-height: 25rem;
  outline: none; /* Remove default outline */
  margin-top: 0.2rem; /* Reduced margin-top */
  resize: vertical;

  &:focus {
      border-color: white;
      box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
  }
`;

const RedText = styled.span`
  color: red;
`
