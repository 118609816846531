import {HelpBoxType} from "../App/Types/helpBox";

export const LB123_COOKIE_NAME = "LB123_"
export const TOKENS_COOKIE_NAME = "tokens"

export const GREETING_TEXT = "Hello, I am your dispatching assistant. You can ask me questions about the loads offered by the shippers and brokers anywhere in the US.  To start, you can ask me to provide loads from your city and state.  I will also help you draft your email and connect with companies offering best loads in the market. "

export const EMAIL_TEMPLATE = "Can you provide more details for the load below?:\n" +
    "notes : insert the load details from the results \n" +
    " \n" +
    "My truck will be (or is) empty in {emptying city} by {emptying time} /   \n" +
    "notes: for now  insert the pick up date as a default date for emptying time. Then we will connect this info to fleet management system and make the process automatic.\n" +
    "\n" +
    "If the price is visible in results :\n" +
    "Is the rate negotiable?\n" +
    "\n" +
    "MC: {carrier's MC} /   notes: extract this info from user's registration details.\n" +
    " /notes: extract from registration details\n" +
    "Name\n" +
    "Email\n" +
    "Cell: XXX.XXX.XXXX\n" +
    "DOT notes: extract this info from user's registration details."


    export const HELP_TEXT_LIST = [
        {
          title: "Show me loads from Atlanta, GA for Flatbed trucks",
          text: "",
        } as HelpBoxType,
        {
          title:
            "Give me loads from Chicago, IL for Reefer trucks within more than 100 miles of transportation distance, but higher than $200 in price.",
          text: "",
        } as HelpBoxType,
        {
          title: "Loads posted in last 19 hours from Phoenix for Step Deck trucks",
          text: "",
        } as HelpBoxType,
        {
          title: "Show me 7 loads with the highest rate per mile for Van trucks from Austin, TX",
          text: "",
        } as HelpBoxType,
        {
          title:
            "Show me loads with the highest prices posted in last 24 hours from Indianapolis for Van trucks",
          text: "",
        } as HelpBoxType,
        {
          title:
            "Loads from Los Angeles for Van trucks higher than 25,000 pounds load weight",
          text: "",
        } as HelpBoxType,
      ] as HelpBoxType[];

export const POPULAR_TRUCK_TYPES = [
  "Flatbed",
  "Power Only",
  "Reefer",
  "Step Deck",
  "Van"
]

export const ALL_TRUCK_TYPES = [
  "Floor",
  "Vented Van or Refrigerated",
  "Vented Van",
  "Flatbed, Van or Reefer",
  "Van, Reefer or Double Drop",
  "Van or Reefer",
  "Open Top Van",
  "Moving Van",
  "Van with Liftgate",
  "Vented Insulated Van or Refrigerated",
  "Vented Insulated Van",
  "Van Intermodal",
  "Flatbed, Van or Reefer",
  "Flatbed or Van",
  "Cargo Vans (1 Ton capacity)",
  "Blanket Wrap Van",
  "Van - Air-Ride",
  "Van",
  "Tanker (Food grade, liquid, bulk, etc.)",
  "Straight Van",
  "Cargo/Small/Sprinter Van",
  "Unspecified Specialized Trailers",
  "Step Deck or Removable Gooseneck",
  "Step Deck Over-Dimension Loads",
  "Step Deck with Loading Ramps",
  "Step Deck Extendable",
  "Step Deck Conestoga",
  "Step Deck",
  "Flatbed, Van or Reefer",
  "Van or Reefer",
  "Refrigerated Carrier with Plant Decking",
  "Roll Top Conestoga",
  "Refrigerated Intermodal",
  "RGN Extendable",
  "Removable Goose Neck & Multi-Axle Heavy Haulers",
  "Flatbed, Van or Reefer",
  "Refrigerated (Reefer)",
  "Power Only (Tow-Away)",
  "Pneumatic",
  "Mobile Home",
  "Maxi or Double Flat Trailers",
  "Live Bottom Trailer",
  "Load-Out",
  "Lowboy Over-Dimension Loads",
  "Lowboy",
  "Landoll Flatbed",
  "Haul and Tow Unit",
  "Hot Shot",
  "Hopper Bottom",
  "Flatbed With Sides",
  "Flatbed, Vented Van or Reefer",
  "Flatbed or Vented Van",
  "Flatbed, Van or Reefer",
  "Van or Flatbed",
  "Flatbed, Step Deck or Van",
  "Flatbed or Step Deck",
  "Flatbed, Van or Reefer",
  "Flatbed",
  "Flatbed Intermodal",
  "Stretch Trailers or Extendable Flatbed",
  "FlatBed - Air-Ride",
  "Flatbed",
  "End Dump",
  "Dump Trucks",
  "Double Drop Extendable",
  "Double Drop",
  "Drive Away",
  "Curtain Van",
  "Container Trailer",
  "Conestoga",
  "Convertible Hopper",
  "B-Train/Supertrain (Canada only)",
  "Boat Hauling Trailer",
  "Conveyor Belt",
  "Beam",
  "Belly Dump",
  "Auto Carrier",
  "Animal Carrier",
  "Two 24 or 28 Foot Flatbeds",
]

