import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

import ThemeToggle from '../Toogle/ThemeToggle';
import { sizes } from '../../Utils/screenUtil';

export const SettingsDropdown = () => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const matches2240px = useMediaQuery('(min-width:2240px)');

  // Close modal if click is outside of the modal
  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('click', handleOutsideClick);
    }

    // Cleanup function to remove the event listener when the component unmounts or showModal changes
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showModal]);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent event bubbling to avoid immediate modal close
    setShowModal((prevVal) => !prevVal);
  };

  return (
    <SettingsFullContainer onClick={handleIconClick}>
      <StyledSettingsIcon sx={{ fontSize: matches2240px ? '30px' : '20px' }} />
      <SettingsSpan>Settings</SettingsSpan>
      {showModal && (
        <ModalContainer ref={modalRef} onClick={(e) => e.stopPropagation()}>
          <SettingCell disabled>
            <SettingPre>Security</SettingPre>
          </SettingCell>
          <SettingCell disabled>
            <SettingPre>Account</SettingPre>
          </SettingCell>
          <SettingCell>
            <ThemeToggle />
          </SettingCell>
        </ModalContainer>
      )}
    </SettingsFullContainer>
  );
};

export const SettingsFullContainer = styled.div`
  position: absolute;
  bottom: 30px;
  color: ${(props) => props.theme.detailsText};
  font-size: 1rem;
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  :hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-weight: 600 !important;
    font-size: 1.6rem !important;
  }
`;

export const SettingsSpan = styled.span``;

export const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${(props) => props.theme.detailsText};
  margin-right: 5px;
  transform: translateY(2px);
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240 pixels */
    transform: translateY(5px);
  }
  @media only screen and (max-width: 1320px) {
    /* Styles for screens less than 1320 pixels */
    transform: translateY(4px);
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425 pixels */
    transform: translateY(5px);
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: -140px;
  left: 20px;
  background-color: ${(props) => props.theme.secondary};
  border: 1px solid ${(props) => props.theme.settingsBorderOutlineColor};
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1251px) {
    /* Styles for screens less than 1251px pixels */
    left: 0px;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    transform: translateX(90px);
  }
`;

export const SettingCell = styled.div<{ disabled?: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.settingsBorderColor};
  height: 40px;
  width: 100%;
  overflow: hidden;
  padding: 4px;
  display: flex;
  align-items: center;
  :hover {
    opacity: ${({ disabled }) => (disabled ? '1' : '.7')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const SettingPre = styled.div`
  font-weight: normal !important;
  color: ${(props) => props.theme.detailsText};
`;
