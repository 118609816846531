import styled from "styled-components";
import {devices} from "../../Utils/screenUtil";

interface TypographyProps {
    color?: string,
    fontSize?: string,
    hoverColor?: string
    bold?: boolean,
    margin?: string,
    padding?: string,
    cursor?: string
}

export const Title = styled.p<TypographyProps>`
    font-family: 'Work Sans', 'Lato', sans-serif;
    color: ${props => props.color ? props.color : props.theme.title};
    font-size: ${props => props.fontSize ? props.fontSize : '20px'};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '0'};
    white-space: initial;


    @media ${devices.mobileS} {
        font-size: 1em;
    }

    @media ${devices.tablet} {
        font-size: 1.2em;
    }
`;

export const Paragraph = styled.p<TypographyProps>`
    font-family: 'Work Sans', 'Lato', sans-serif;
    color: ${props => props.color ? props.color : props.theme.text};
    font-size: ${props => props.fontSize ? props.fontSize : '0.9em'};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '0'};


    @media ${devices.mobileS} {
        font-size: 1em;
    }
`;

export const Text = styled(Paragraph)`
    font-family: 'Work Sans', 'Roboto', sans-serif;
    color: ${props => props.color ? props.color : props.theme.text};
    opacity: 1;


    @media ${devices.mobileS} {
        font-size: 0.8em;
    }
`;

export const LogoTitle = styled.p<TypographyProps>`
    font-family: 'Work Sans', 'Lato', sans-serif;
    color: ${props => props.color ? props.color : props.theme.title};
        // font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '0'};


    @media ${devices.mobileS} {
        font-size: 1.3em;
    }

    @media ${devices.mobileL} {
        font-size: 1.3em;
    }

    @media ${devices.tablet} {
        font-size: 1.4em;
    }

    @media ${devices.laptop} {
        font-size: 1.5em;
    }

    @media ${devices.desktop} {
        font-size: 2em;
    }
`;


export const HeadTitle = styled(Title)`
    @media ${devices.mobileS} {
        font-size: 1.4em;
    }

    @media ${devices.mobileL} {
        font-size: 1.5em;
    }

    @media ${devices.tablet} {
        font-size: 1.6em;
    }

    @media ${devices.laptop} {
        font-size: 1.8em;
    }

    @media ${devices.desktop} {
        font-size: 2.2em;
    }
`;

