export function formatPhoneNumber(number: any | string): string {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return number
}


export function numberWithCommas(x: any): string {
    if (x === null || x === undefined) {
        return '';
    }

    let parts = x.toString().split(".");
    let whole = parts[0];
    let decimal = parts[1];

    if (decimal) {
        if (decimal.length === 1) {
            decimal += "0";  
        } else if (decimal.length > 2) {
            const decimalAsNumber = Number("0." + decimal);
            decimal = (Math.round(decimalAsNumber * 100) / 100).toString().split(".")[1];
            if (!decimal) {
                decimal = "";  
            } else if (decimal.length === 1) {
                decimal += "0";  
            }
        }
    } else {
        decimal = "";  
    }

    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return decimal ? `${whole}.${decimal}` : whole;
}
