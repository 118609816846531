import { FC, useState, useRef, useEffect } from "react";
import { Paragraph } from "../../App/Styles/Typography.styled";
import { HiddenButton } from "../../App/Styles/Button.styled";
import { POPULAR_TRUCK_TYPES, ALL_TRUCK_TYPES } from "../../Other/constants";
import { VectorImage } from "../../App/Styles/Image.styled";
import downSvg from "../../Assets/down.svg";
import styled from "styled-components";

interface TypePromptProps {
  handleSelect: (value: string) => void,
  handleToggle: () => void
}

interface DropDownBoxProps {
  show: boolean,
  height?: string
} 

const TypePrompt: FC<TypePromptProps> = ({ handleSelect, handleToggle }) => {
  const [isDropDownShown, setDropDownShown] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Ignore scroll wheel propogation to chat context
    if (menuRef.current) {
      menuRef.current.addEventListener("wheel", (event) => {
        event.stopPropagation();
      })
    }
  }, []);

  // Use useEffect to add and remove event listeners for clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) && // Is not the menu div
        !(event.target as HTMLElement).closest('span') && // Ignore clickable text
        !(event.target as HTMLElement).closest('img') // Ignore dropdown arrow
      ) setDropDownShown(false);
    };

    if (isDropDownShown) {
      document.addEventListener('mousedown', handleClickOutside);
      handleToggle();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropDownShown]);

  return (
    <>
      <Paragraph>
        Here are some common truck types:&nbsp;
        {POPULAR_TRUCK_TYPES.map((item, index) => (
          <>
            <HighlightButton onClick={() => handleSelect(item)} key={index}>
             {item}
            </HighlightButton>
            {index + 1 !== POPULAR_TRUCK_TYPES.length && ", "}
          </>
        ))}.
        You can find more types of trucks&nbsp;
        <TruckTypeSelector onClickCapture={() => setDropDownShown(previous => !previous)}>
          here
        </TruckTypeSelector>
        &nbsp;
        <DropDownArrow
          width="0.8em"
          height="0.8em"
          src={downSvg}
          isActive={isDropDownShown}
          onClickCapture={() => setDropDownShown(previous => !previous)}
        />.
      </Paragraph>
      <DropDownBox
        tabIndex={-1}
        ref={menuRef}
        show={isDropDownShown}
      >
        {ALL_TRUCK_TYPES.map((option, index) => (
          <TruckTypeOption key={index} onClick={() => handleSelect(option)}>
            {option}
          </TruckTypeOption>
        ))}
      </DropDownBox>
    </>
  )
}

const HighlightButton = styled(HiddenButton)`
  color: ${({theme}) => theme.accent};
  border: ${({theme}) => theme.borderColor};
  border-radius: 0.25em;
  padding: 0.15em;
`;

const TruckTypeSelector = styled.span`
  color: ${({theme}) => theme.accent};
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  padding: 0.1rem;
  border: ${({theme}) => theme.borderColor};
  border-radius: 0.25em;
  padding: 0.15em;
`;

const DropDownBox = styled.div<DropDownBoxProps>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  text-wrap: nowrap;
  position: absolute;
  top: 105%;
  right: 0;
  padding: 1em;
  background-color: ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.text};
  border: 1px solid rgba(132, 132, 132, 0.23);
  border-radius: 10px;
  height: ${props => props.height ? props.height : '20em'};
  overflow-y: scroll;
  outline: none;
  z-index: 1001;
`;

const TruckTypeOption = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.secondary};
  border-radius: 0.2em;
  color: ${({theme}) => theme.accent};
  cursor: pointer;
  padding: 0.5em;

  &:hover {
      background-color: ${({theme}) => theme.hover};
  }
`;

const DropDownArrow = styled(VectorImage)<{ isActive: boolean }>`
  filter: ${({ theme }) => theme.activeFilter};
  transition: ${({ theme }) => theme.transition};
  transform: rotate(${props => props.isActive ? '0' : '-0.25'}turn);
  cursor: pointer;
`;

export default TypePrompt
