import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

import { VectorImage } from '../../App/Styles/Image.styled';
import {
  HeadTitle,
  Paragraph as OriginalParagraph,
} from '../../App/Styles/Typography.styled';
import { HiddenButton } from '../../App/Styles/Button.styled';
import profileSvg from '../../Assets/profile.svg';
import logoSvg from '../../Assets/logo.svg';
import closeSvg from '../../Assets/close.svg';
import { AuthenticationContext } from '../../Context/Auth/AuthContext';
import { sizes } from '../../Utils/screenUtil';
import { ProvidersDropdown } from '../../Components/ProvidersDropdown/ProvidersDropdown';

interface NavigationLayoutProps {
  toggleLeftbar: () => void;
}

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  toggleLeftbar,
}) => {
  const authContext = useContext(AuthenticationContext);
  const [popOverEnabled, setPopOverEnabled] = useState<boolean>(false);

  const handleProfileClick = () => {
    setPopOverEnabled((previous) => !previous);
  };

  return (
    <Container>
      <HamburgerDiv onClick={toggleLeftbar}>
        <MenuIcon sx={{ fontSize: '50px' }} />
      </HamburgerDiv>
      <LogoContainer to="/">
        <VectorImage src={logoSvg} />
        <HeadTitle bold={true}>TarmacAI</HeadTitle>
      </LogoContainer>
      <ProvidersDropdown />
      {/* This dropdown appears on tablet screens or less */}
      <LinkContainer
        href="https://tarmac-ai.com"
        gridColumn={2}
        target="_blank"
        rel="noopener noreferrer"
        mobileDisplayed={true}
      >
        <Paragraph>COMPANY</Paragraph>
      </LinkContainer>
      {/* <LinkContainer */}
      {/*     href="https://docs.google.com/forms/d/e/1FAIpQLSfK1O1lH9EDGrCZlCkyMaJ4CmzZBnF6onIcAhSEyQvr80IGLg/viewform" */}
      {/*     gridColumn={3} target="_blank" rel="noopener noreferrer" */}
      {/* > */}
      <LinkContainer href="/contact" gridColumn={3}>
        <Paragraph>CONTACT</Paragraph>
      </LinkContainer>
      <LinkContainer
        href="/help"
        gridColumn={4}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph>HELP</Paragraph>
      </LinkContainer>
      <ProfileContainer>
        <HiddenButton onClick={handleProfileClick}>
          <VectorImage src={profileSvg} height="30px" width="30px" />
        </HiddenButton>
        <ProfileDropdown tabIndex={0} enabled={popOverEnabled}>
          <ProfileDropdownBanner>
            <HiddenButton onClick={() => setPopOverEnabled(false)}>
              <VectorImage src={closeSvg} />
            </HiddenButton>
          </ProfileDropdownBanner>
          <DropdownItem href="/auth">
            {authContext?.tokens ? (
              <HiddenButton padding="1rem" onClick={authContext.logoutListener}>
                <Paragraph>Logout</Paragraph>
              </HiddenButton>
            ) : (
              <Paragraph>Login</Paragraph>
            )}
          </DropdownItem>
        </ProfileDropdown>
      </ProfileContainer>
    </Container>
  );
};

export default NavigationLayout;

const HamburgerDiv = styled.div`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.arrowIcon};
  bottom: -60px;
  left: -10px;
  display: none;

  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than tablet pixels */
    display: block;
  }
`;

const Paragraph = styled(OriginalParagraph)`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.detailsText};
`;

const Container = styled.div`
  grid-area: nav;
  position: relative;
  width: 100%;
  margin-left: 1rem;
  display: grid;
  gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-columns: minmax(50px, auto);
  grid-template-rows: 1fr;
  border-bottom: 2px solid rgba(178, 165, 165, 0.18);
  height: 4rem;
  background-color: ${({ theme }) => theme.primary};
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  grid-column: 1;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;

const LinkContainer = styled.a<{
  gridColumn: number | null;
  mobileDisplayed?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: ${(props) => (props?.gridColumn ? props.gridColumn : 1)};
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    display: none};
  }
`;

const ThemeContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ProfileDropdown = styled.div<{ enabled: boolean }>`
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: ${({ theme }) => theme.text};
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.settingsBorderOutlineColor};
  border-radius: 10px;
  position: absolute;
  align-items: end;
  padding: 1rem;
  transform: translate(2rem, 5.5rem);
  opacity: 0;
  float: end;
  margin-right: 6px;
  ${(props) =>
    props.enabled ? 'opacity: 1; transform: translate(1rem, 5.5rem);' : ''}
`;

const ProfileDropdownBanner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid rgba(178, 165, 165, 0);
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: 0.7;
    border-color: rgba(178, 165, 165, 0.2);
  }
`;

const ProfileContainer = styled.div`
  color: ${({ theme }) => theme.text};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  margin-right: 2rem;
  gap: 1.5rem;
  grid-column: 6;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;
