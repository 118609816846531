import { useState } from "react";
import styled from "styled-components";
import ContactCard from "./ContactCard";
import NavigationLoginLayout from "../../Layouts/Navigation/NavigationLogInLayout";
import BottombarLayout from "../../Layouts/Bottombar/BottombarLayout";
import useEmail from "../../Hooks/useApi/useEmail";
import { Popup } from "../../Components/Popup/Popup";

const ContactPage = () => {
    const { sendEmail } = useEmail();
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [message, setMessage] = useState<string>('');
    const [company, setCompany] = useState<string | null>('')
    const [dotNumber, setDotNumber] = useState<string | null>('')
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccess] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleMessageSend = async () => {
        if (!firstName) return setErrorMessage("Missing required first name");
        if (!lastName) return setErrorMessage("Missing required last name");
        if (!email) return setErrorMessage("Missing required email");
        if (!message) return setErrorMessage("Missing required message");

        setLoading(true);
        const success = await sendEmail({ firstName, lastName, message, email, company, dotNumber });
        if (!success) return setErrorMessage("Something went wrong sending your message.");
        setSuccess("Thank you for contacting us! We will reach out to you soon.")
        setLoading(false);
    }

    return (
        <PageContainer>
            <NavigationLoginLayout/>
            {successMessage && <Popup title="Success!" subtitle={successMessage} handleExit={() => setSuccess("")}/>}
            <CardContainer>
                <ContactCard 
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email} 
                    setEmail={setEmail} 
                    message={message}
                    setMessage={setMessage}
                    company={company}
                    setCompany={setCompany}
                    dotNumber={dotNumber}
                    setDotNumber={setDotNumber}
                    errorMessage={errorMessage} 
                    setError={setErrorMessage} 
                    loading={isLoading}
                    sendMessage={handleMessageSend}
                />
            </CardContainer>
            <BottombarLayout/>
        </PageContainer>
    )
}

export default ContactPage

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
    height: 100vh;
`;

const CardContainer = styled.div`
    padding-left: 7%;
`;
