import { KeyboardEvent, useContext, useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    GreetingTextContainer,
    HelpBoxList,
    Input,
    InputContainer,
    SendButton
} from "./ChatPage.styled";
import MessageRecycler, { MessageItem } from "../../../Components/Recycler/MessageRecycler";
import { Image } from "../../../App/Styles/Image.styled";
import CircularProgress from '@mui/material/CircularProgress';
import useChatApi from "../../../Hooks/useApi/useChatApi";
import { Title } from "../../../App/Styles/Typography.styled";
import { GREETING_TEXT, HELP_TEXT_LIST } from "../../../Other/constants";
import { IResponse } from "../../../Models/BaseModel";
import HelpBox from "../../../Components/HelpBox/HelpBox";
import { getRandomElements } from "../../../Utils/collectionUtils";
import { HelpBoxType } from "../../../App/Types/helpBox";
import { AuthenticationContext } from '../../../Context/Auth/AuthContext';
import { PluginContext } from '../../../Context/Plugin/PluginContext';
import { ILoad } from '../../../Models/LoadModel';

const ChatPage = () => {
    const { sendMessage } = useChatApi();
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState<MessageItem[]>([]);
    const [isProgress, setIsProgress] = useState<boolean>(false);
    const [helpList, setHelpList] = useState<HelpBoxType[]>(getRandomElements(HELP_TEXT_LIST, 4));
    const [responseCompleted, setResponseCompleted] = useState<boolean>(false);
    const navigate = useNavigate();
    const authContext = useContext(AuthenticationContext);
    const pluginContext = useContext(PluginContext);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await onSendMessageClickListener();
        }
    }

    const onSendMessageClickListener = async (query?: string) => {
        const textQuery = query ? query : inputText;
        const boards = [];

        if (isProgress) return;
        if (query === "" && inputText === "") return;
        if (inputRef.current !== null) inputRef.current!.value = "";

        // Redirect to auth if not found
        const tokens = authContext?.tokens;
        if (tokens === null) {
            console.log(tokens);
            return navigate("/auth");
        }

        setMessages(prevState => [...prevState, {isUser: true, title: textQuery} as MessageItem]);

        // Notify user no providers are enabled
        if (!pluginContext!.LB123Plugin && !pluginContext!.truckstopPlugin) {
            console.error("User not authorized with any boards");
            setMessages(prevState => [...prevState, {
                isUser: false,
                title: "You are not authorized with any providers. Please log in to a provider to get results."
            } as MessageItem]);
            setResponseCompleted(true); // Set response completed
            return;
        }

        if (pluginContext!.LB123Plugin) boards.push("123LoadBoard");
        if (pluginContext!.truckstopPlugin) boards.push("truckstop");

        setIsProgress(true);
        const response = await sendMessage(textQuery, boards);

        if (response.status === 200 || response.status === 201) {
            if (Array.isArray(response!.data.data)) {
                const data = response!.data as IResponse<ILoad[]>;
                if (data.data.length === 0) {
                    // Empty response
                    setMessages(prevState => [...prevState, {isUser: false, title: "There were no results for your query."} as MessageItem]);
                } else {
                    // Load table response
                    setMessages(prevState => [...prevState, { isUser: false, loadList: data.data } as MessageItem]);
                }
            } else {
                // String response
                setMessages(prevState => [...prevState, {isUser: false, title: response!.data.data} as MessageItem]);
            }
        } else if (response.status === 400 || response.status === 404) {
            // Handle 400 errors
            setMessages(prevState => [...prevState, {
                isUser: false,
                title: response!.data.data.detail,
                promptTruckType: (/\bequipment\b\s+.*?\btype\b|\btype\b\s+.*?\bequipment\b/gi.test(response!.data.data.detail))
            } as MessageItem]);
        } else {
            // Something unexpectedly went wrong
            setMessages(prevState => [...prevState, {isUser: false, title: "Something went wrong processing your query."} as MessageItem]);
            if (pluginContext!.truckstopPlugin) pluginContext!.setTruckstopPlugin(false);
            navigate("/error"); // Redirect to error page
        }

        setIsProgress(false);
        setResponseCompleted(true); // Set response completed
    };

    const onHelpBoxClickListener = async (query: string) => {
        if (inputRef.current !== null) {
            inputRef.current.value = query;
        }
        setInputText(() => query);
        await onSendMessageClickListener(query);
    };

    // Handle selection of a trucktype from the prompt
    const handleTruckTypeSelect = useCallback((truckType: string) => {
        if (inputRef.current !== null) {
            const text = `Required equipment type is ${truckType}`;
            inputRef.current.value = text;
            setInputText(text);
            inputRef.current.focus(); // Focus on input after a selection
        }
    }, [setInputText]);

    useEffect(() => {
        if (responseCompleted && inputRef.current) {
            inputRef.current.focus(); // Focus on the input element after response is completed
            setResponseCompleted(false); // Reset the response completed state
        }
    }, [responseCompleted]);

    return (
        <Container>
            {messages.length !== 0 ? <MessageRecycler messages={messages} handleSelect={handleTruckTypeSelect}/> :
                <>
                    <GreetingTextContainer>
                        <Title data-testid="greeting-text">{GREETING_TEXT}</Title>
                    </GreetingTextContainer>
                    <HelpBoxList>
                        {helpList.map((item, index) => (
                            <HelpBox key={index} item={item} onClick={() => onHelpBoxClickListener(item.title)} />
                        ))}
                    </HelpBoxList> </>}
            <InputContainer>
                <Input
                    type="text" spellCheck={false} ref={inputRef} placeholder="Type your query here..."
                    onKeyDown={event => handleKeyDown(event)}
                    onChange={event => setInputText(() => event.target.value)}
                />
                {isProgress ? <CircularProgress color="warning" /> : <SendButton onClick={() => onSendMessageClickListener()}>
                    <Image src='https://www.svgrepo.com/show/520931/send-2.svg' width='1.5em' height='1.5em' />
                </SendButton>}
            </InputContainer>
        </Container>
    );
};

export default ChatPage;
