import {useContext, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useChatApi from "../../../Hooks/useApi/useChatApi";
import {PluginContext} from "../../../Context/Plugin/PluginContext";

const LoginRedirectPage = () => {
    const pluginContext = useContext(PluginContext);
    const navigate = useNavigate();
    const {login123LB} = useChatApi();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        console.log('start effect');
        (async () => {
            try {
                const value = queryParams.get('code'); // Retrieve the value of a specific query parameter
                console.log(value)
                if (value === null) {
                    console.log('value null')
                    return;
                }
                const data = await login123LB(value);
                if (data === null) {
                    console.log('data null')
                    return;
                }
                console.log(data);
                pluginContext?.setLB123Plugin(true);
                navigate("/");
            } catch (e) {

            }
        })();
        // return () => {
        //     // Clean-up logic here (if needed)
        // };
    }, [pluginContext, navigate, login123LB, location]);

    return (
        <>
        </>
    );
};

export default LoginRedirectPage;
