import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { HeadTitle, Paragraph } from '../../App/Styles/Typography.styled';
import ErrorBackground from '../../Assets/errorBackground.png';
import NavigationLoginLayout from '../../Layouts/Navigation/NavigationLogInLayout';
import BottombarContent from '../../Layouts/Bottombar/BottmbarContent';

interface ErrorPageProps {
  notfound?: boolean | undefined
}

const ErrorPage: React.FC<ErrorPageProps> = ({ notfound }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <NavigationLoginLayout />
      <Content>
        <StyledHeadTitle bold={true}>{notfound ? "Not Found" : "Oops"}</StyledHeadTitle>
        <StyledParagraph>
          {notfound ? "This page does not exist." : "Looks like something went wrong! Let’s fix it."}
        </StyledParagraph>
        <HomeButton onClick={() => navigate("/")}>Back to home</HomeButton>
      </Content>
      <BottombarContent />
    </Container>
  );
};

export default ErrorPage;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  justify-content: space-between;
  height: 100vh;
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(${ErrorBackground});
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
`;

const StyledHeadTitle = styled(HeadTitle)`
  font-size: 96px;
  color: #FFFFFF;
  font-weight: 700;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 26px;
  color: white;
  margin: 20px 0;
`;

const HomeButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: #AD8449;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #945e2b;
  }
`;
