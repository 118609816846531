import React, {createContext, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ChildrenProps} from '..';


import useCookie from "react-use-cookie";
import {parseCookieData} from '../../Utils/cookieUtil';
import jwt_decode from "jwt-decode";
import {IAccessToken, ITokens} from "../../Models/UserModel";
import {LoadingContext} from "../Loading/LoadingContext";
import {TOKENS_COOKIE_NAME} from '../../Other/constants';

interface IAuthContext {
    tokens: ITokens | null,
    accessToken: IAccessToken | null,
    loginListener: (data: ITokens) => void,
    logoutListener: () => void,
}

const AuthenticationContext = createContext<IAuthContext | null>(null);

const AuthenticationContextProvider = ({children}: ChildrenProps) => {
    const loadingContext = useContext(LoadingContext);
    const navigate = useNavigate();
    // states
    const [cookie, setCookie] = useCookie(TOKENS_COOKIE_NAME, "");
    const [tokens, setTokens] = useState<ITokens | null>(parseCookieData(cookie));
    const [accessToken, setAccessToken] = useState<IAccessToken | null>(null);

    // effects
    useEffect(() => {
        if (cookie !== '') {
            const parsedTokens = JSON.parse(cookie) as ITokens;
            setTokens(parsedTokens);
            console.log(`set tokens ${parsedTokens}`);
        } else {
            setTokens(null);
            console.log(`set tokens ${tokens}`);
        }
    }, [cookie]);

    useEffect(() => {
        if (tokens !== null) {
            const decoded = jwt_decode(tokens?.access!) as IAccessToken
            setAccessToken(decoded)
            console.log(`User ID - ${decoded.user_id}`)
        } else {
            setAccessToken(null);
        }
    }, [tokens]);

    // callbacks
    const loginCallback = async (data: ITokens) => {
        loadingContext?.setLoading(true);
        setCookie(JSON.stringify(data));
        console.log('AUTH_CONTEXT: LOGIN SUCCESS!');
        setTokens(data);
        navigate('/');
        loadingContext?.setLoading(false);
    };
    const logoutCallback = async () => {
        loadingContext?.setLoading(true);
        setCookie('');
        navigate('/auth');
        console.log('AUTH_CONTEXT: LOGOUT');
        loadingContext?.setLoading(false);
    };

    const contextData = {
        tokens: tokens,
        accessToken: accessToken,
        loginListener: loginCallback,
        logoutListener: logoutCallback
    } as IAuthContext

    return (
        <AuthenticationContext.Provider value={contextData}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export {AuthenticationContextProvider, AuthenticationContext};
