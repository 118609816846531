import React from 'react';
import styled from 'styled-components';
import {devices} from '../../Utils/screenUtil';
import BottombarContent from './BottmbarContent';

const BottombarLayout = () => {
    return (
        <Container>
            <BottombarContent/>
        </Container>
    );
};

export default BottombarLayout;

const Container = styled.div`
    // grid-area: bottom;
    // position: fixed;
    // bottom: 0;
    // height: 5000px;
    // width: 100%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding: 0 0.5rem;
    // z-index: 10;
    // background-color: ${({theme}) => theme.secondary};
    // @media ${devices.laptop} {
    //     display: none;
    // }
`;
