export function timeAgo(dateStr: string): string {
    const date = new Date(dateStr);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - date.getTime();
    const seconds = Math.floor(timeDifference / 1000);

    const periods: [string, number][] = [
        ['week', 60 * 60 * 24 * 7],
        ['day', 60 * 60 * 24],
        ['hour', 60 * 60],
        ['minute', 60],
        ['second', 1]
    ];
    for (const [periodName, periodSeconds] of periods) {
        if (seconds >= periodSeconds) {
            const timeAgoValue = Math.floor(seconds / periodSeconds);
            return `${timeAgoValue} ${periodName}${timeAgoValue > 1 ? 's' : ''} ago`;
        }
    }
    return "Just now";
}

export function formatDateTime(dateTimeStr: string): string {
    const date = new Date(dateTimeStr);
    // Set minute offsets for date to get correct values from methods
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
}
