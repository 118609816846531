import * as React from 'react';
import { useContext, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import Tabs from '@mui/material/Tabs';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paragraph, Title } from "../../App/Styles/Typography.styled";
import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../App/Styles/Container.styled";
import { ILoad, ILoadDetail, ILoadFMCSADetail } from "../../Models/LoadModel";
import { formatDateTime, timeAgo } from "../../Utils/datetimeUtil";
import useLoadsApi from "../../Hooks/useApi/useLoadsApi";
import { IResponse } from "../../Models/BaseModel";
import EmailDraftModal from "../Modal/EmailDraftModal";
import { PortalContext } from "../../Context/Portal/PortalContext";
import { Image, VectorImage } from "../../App/Styles/Image.styled";
import lb123 from "../../Assets/123loadboard.png";
import truckStop from "../../Assets/truckStop.png";
import { formatPhoneNumber, numberWithCommas } from '../../Utils/numberUtil';
import up from "../../Assets/up.svg";
import down from "../../Assets/down.svg";
import Map from "../Maps/Map";
import { isNumber, isString } from "../../Utils/guardUtil";

function checkCarrierNumbersVariables(mcNumber1: any, mcNumber2: any, dotNumber: any): {
    carrierNumber: string | null,
    isDot: boolean
} {
    if (isNumber(mcNumber1)) {
        return { carrierNumber: `${mcNumber1}`, isDot: false };
    }
    if (isString(mcNumber2)) {
        return { carrierNumber: mcNumber2, isDot: false };
    }
    if (isString(dotNumber)) {
        return { carrierNumber: dotNumber, isDot: true };
    }
    return { carrierNumber: null, isDot: false };
}

function Row(props: { row: ILoad }) {
    const { row } = props;
    const portalContext = useContext(PortalContext);
    const { getLB123DetailsAPI, getTruckstopDetailsAPI, getFMCSADetailsAPI } = useLoadsApi();
    const [open, setOpen] = useState(false);
    const [moreInfoOpen, setMoreInfoOpen] = useState(false);
    const [value, setValue] = useState<string>('one');
    const [loading, setLoading] = useState(false);
    const [detailsCache, setDetailsCache] = useState<{ [key: string]: { detail: ILoadDetail | null, fmcsaDetail: ILoadFMCSADetail | null } }>({});
    const url_123LoadBoard = process.env.REACT_APP_123LB_LOADS_URL + row.external_id;

    const memoizedOrigin = useMemo(() => [Number(row.origin_latitude), Number(row.origin_longitude)], [row.origin_latitude, row.origin_longitude]);
    const memoizedDestination = useMemo(() => [Number(row.destination_latitude), Number(row.destination_longitude)], [row.destination_latitude, row.destination_longitude]);

    const onRowClickListener = async (load_id: string) => {
        setOpen(!open);
        if (!open) {
            if (!detailsCache[load_id]) {
                setLoading(true);
                const isTruckStop = row.board !== "123LoadBoard";
                const detailData = isTruckStop ? await getTruckstopDetailsAPI(load_id) : await getLB123DetailsAPI(load_id);
                const data = detailData.data as ILoadDetail;
                const { carrierNumber, isDot } = checkCarrierNumbersVariables(row.mc_number, data.mc_number, data.dot_number);
                const fmcsa_data = await getFMCSADetailsAPI(carrierNumber, isDot) as IResponse<ILoadFMCSADetail>;
                const newCache = {
                    ...detailsCache,
                    [load_id]: {
                        detail: data || null,
                        fmcsaDetail: fmcsa_data?.data || null
                    }
                };
                setDetailsCache(newCache);
                setLoading(false);
            }
        }
    };

    const onSendEmailButtonClickListener = (load: ILoad, mail?: string) => {
        load.email = mail;
        portalContext?.setModal(<EmailDraftModal load={load} />);
        portalContext?.setIsOpen(true);
    };

    const detail = detailsCache[row.external_id]?.detail;
    const fmcsaDetail = detailsCache[row.external_id]?.fmcsaDetail;

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const formatData = (key: string, value: any) => {
        switch (key) {
            case 'allowedToOperate':
                return value === 'Y' ? <GreenText>Yes</GreenText> : <RedText>No</RedText>;
            case 'brokerAuthorityStatus':
                return value === 'A' ? <GreenText>Active</GreenText> : <RedText>Inactive</RedText>;
            case 'safetyRating':
                return value === 'Satisfactory' ? <GreenText>Satisfactory</GreenText> :
                    value === 'Unsatisfactory' ? <RedText>Unsatisfactory</RedText> :
                        'Unavailable';
            case 'statusCode':
                return value === 'A' ? <GreenText>Active</GreenText> : <RedText>Inactive</RedText>;
            case 'bipdInsuranceOnFile':
            case 'bondInsuranceOnFile':
            case 'cargoInsuranceOnFile':
                return value === '0' ? 'Data Not Available' : `$${numberWithCommas(Number(value) * 10000)}`;
            case 'totalPowerUnits':
                return Number(value) > 2 ? <GreenText>More than 2</GreenText> : <RedText>Less than 2</RedText>;
            default:
                return value;
        }
    };

    const handleMoreInfoClick = () => {
        setMoreInfoOpen(!moreInfoOpen);
    };

    return (
        <React.Fragment>
            <TableRow>
                <StyledTableCell>
                    <CustomIconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => onRowClickListener(row.external_id)}
                        color="success"
                    >
                        {open ? <VectorImage src={up} /> : <VectorImage src={down} />}
                    </CustomIconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                    <StyledParagraph>{timeAgo(row.published_at!)}</StyledParagraph>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexColumn justify="center" alignItems="center" gap="0.3rem">
                        <StyledParagraph>{row.origin_city}</StyledParagraph>
                        <StyledTitle>{row.origin_state}</StyledTitle>
                    </FlexColumn>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexColumn justify="center" alignItems="center" gap="0.3rem">
                        <StyledParagraph>{row.destination_city}</StyledParagraph>
                        <StyledTitle>{row.destination_state}</StyledTitle>
                    </FlexColumn>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexRow justify="center">
                        <StyledParagraph>{row.company_name}</StyledParagraph>
                    </FlexRow>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexColumn justify="center" alignItems="center" gap="0.3rem">
                        <StyledParagraph>{row.weight ? `${numberWithCommas(row.weight)} lbs` : null}</StyledParagraph>
                    </FlexColumn>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexRow justify="center">
                        <StyledParagraph>{row.equipment_type}</StyledParagraph>
                    </FlexRow>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexColumn justify="center" alignItems="center" gap="0.3rem">
                        <PriceTitle color="#8BFFCF">${numberWithCommas(row.total_price)}</PriceTitle>
                        <StyledParagraph>RPM: ${numberWithCommas(row.rate_per_mile)}</StyledParagraph>
                    </FlexColumn>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <FlexRow justify="center">
                        <StyledParagraph>{numberWithCommas(row.mileage)}</StyledParagraph>
                    </FlexRow>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <StyledParagraph>{formatDateTime(row.pickup!)}</StyledParagraph>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <a href={row.board?.toLowerCase() === "123loadboard" ? url_123LoadBoard : "https://www.truckstop.com"}
                        target="_blank" rel="noopener noreferrer">
                        <StyledParagraph>
                            <Image src={row.board?.toLowerCase() === "123loadboard" ? lb123 : truckStop} height="2rem"
                                width="2rem" />
                        </StyledParagraph>
                    </a>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ContentArea>
                            <div>
                                <StyledTabs value={value} onChange={handleChange}>
                                    <StyledTab value="one" label="Details" />
                                    <StyledTab value="two" label="Market Analysis" />
                                    <StyledTab value="three" label="Background Screening" />
                                </StyledTabs>
                                <Box sx={{ margin: 1 }}>
                                    {loading ? (
                                        <DetailsContainer>
                                            <DetailFlexColumn justify="flex-start" gap="0.5rem">
                                                <BrightSkeleton animation="wave" variant="text" width="80%" height={30} />
                                                <BrightSkeleton animation="wave" variant="text" width="60%" height={30} />
                                                <BrightSkeleton animation="wave" variant="text" width="40%" height={30} />
                                                <BrightSkeleton animation="wave" variant="text" width="50%" height={30} />
                                                <BrightSkeleton animation="wave" variant="text" width="70%" height={30} />
                                                <BrightSkeleton animation="wave" variant="text" width="90%" height={30} />
                                            </DetailFlexColumn>
                                        </DetailsContainer>
                                    ) : (
                                        <>
                                            <DetailsContainer>
                                                {value === 'one' && (
                                                    <DetailFlexColumn justify="flex-start" gap="0.5rem">
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Company Name:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{row?.company_name}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Contact Name:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{detail?.contact_name}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Phone:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{formatPhoneNumber(detail?.phone)}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Email:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{detail?.email}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Description:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{detail?.description}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Notes:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{detail?.notes}</DetailsParagraph>
                                                        </DetailItem>
                                                    </DetailFlexColumn>
                                                )}
                                                {value === 'two' && (
                                                    <MarketPriceContainer>
                                                        <MarketPriceHeader>
                                                            Average Market Price
                                                        </MarketPriceHeader>
                                                        {row.board !== "123LoadBoard" && !detail?.average_rate_for_mileage ? (
                                                            <Paragraph>Coming Soon for Truckstop</Paragraph>
                                                        ) : (
                                                            <MarketPriceText>
                                                                ${numberWithCommas(detail?.average_rate_for_mileage)} (MRPM ${numberWithCommas(detail?.avg_rate_per_mile)})
                                                            </MarketPriceText>
                                                        )}
                                                    </MarketPriceContainer>
                                                )}
                                                {value === 'three' && (
                                                    <DetailFlexColumn justify="flex-start" gap="0.5rem">
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>DOT:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{fmcsaDetail?.dotNumber}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>MC:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{detail?.mc_number}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Legal Name:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{fmcsaDetail?.legalName}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Physical Address:</DetailsParagraphHeader>
                                                            <DetailsParagraph>
                                                                {[fmcsaDetail?.phyStreet, fmcsaDetail?.phyCity, fmcsaDetail?.phyState, fmcsaDetail?.phyZipcode, fmcsaDetail?.phyCountry].filter(Boolean).join(', ')}
                                                            </DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Allowed to Operate:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{formatData('allowedToOperate', fmcsaDetail?.allowedToOperate)}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Status Code:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{formatData('statusCode', fmcsaDetail?.statusCode)}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailsParagraphHeader>
                                                            Interstate Authority <br />
                                                            (Broker Authority Status): {formatData('brokerAuthorityStatus', fmcsaDetail?.brokerAuthorityStatus)}
                                                        </DetailsParagraphHeader>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>BIPD Insurance:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{formatData('bondInsuranceOnFile', fmcsaDetail?.bondInsuranceOnFile)}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Cargo Insurance:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{formatData('cargoInsuranceOnFile', fmcsaDetail?.cargoInsuranceOnFile)}</DetailsParagraph>
                                                        </DetailItem>
                                                        <DetailItem>
                                                            <DetailsParagraphHeader>Total Inspection in last 24 months:</DetailsParagraphHeader>
                                                            <DetailsParagraph>{fmcsaDetail?.vehicleInsp}</DetailsParagraph>
                                                        </DetailItem>
                                                        <MoreInfoText onClick={handleMoreInfoClick}>
                                                            {moreInfoOpen ? 'Show Less' : 'More Info'}
                                                        </MoreInfoText>
                                                        {moreInfoOpen && (
                                                            <BlurBackground>
                                                                <DetailFlexColumnMoreInfo justify="flex-start" gap="0.5rem">
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Out of Service Date:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.oosDate || 'None'}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Safety Rating:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{formatData('safetyRating', fmcsaDetail?.safetyRating)}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Total Power Units:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.totalPowerUnits}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Major crashes:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.crashTotal === "0" ? <GreenText>{fmcsaDetail?.crashTotal}</GreenText> : <RedText>{fmcsaDetail?.crashTotal}</RedText>}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Total drivers:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.totalDrivers}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>Towaway crash:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.towawayCrash}</DetailsParagraph>
                                                                    </DetailItem>
                                                                    <DetailItem>
                                                                        <DetailsParagraphHeader>DBA name:</DetailsParagraphHeader>
                                                                        <DetailsParagraph>{fmcsaDetail?.dbaName}</DetailsParagraph>
                                                                    </DetailItem>
                                                                </DetailFlexColumnMoreInfo>
                                                            </BlurBackground>
                                                        )}
                                                    </DetailFlexColumn>
                                                )}
                                            </DetailsContainer>
                                        </>
                                    )}
                                    <FlexRow justify="flex-start" gap="0.5rem" marginBottom="1.5rem">
                                        <EmailButton onClick={() => onSendEmailButtonClickListener(row, detail?.email)}>
                                            Send email
                                        </EmailButton>
                                        <DialButton>
                                            Dial
                                        </DialButton>
                                    </FlexRow>
                                </Box>
                            </div>
                            {loading ? (
                                <MapLoadingArea>
                                    <BrightSkeleton animation="wave" variant="rectangular" width="100%" height="100%" />
                                </MapLoadingArea>
                            ) : (
                                <MapArea>
                                    <Map
                                        origin={memoizedOrigin as [number, number]}
                                        destination={memoizedDestination as [number, number]}
                                        originCity={row?.origin_city}
                                        originState={row?.origin_state}
                                        destinationCity={row?.destination_city}
                                        destinationState={row?.destination_state}
                                    />
                                </MapArea>
                            )}
                        </ContentArea>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}

type Props = {
    loadList: ILoad[]
}

const CollapsibleTable: React.FC<Props> = ({ loadList }) => {
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        <StyledTableCell align="center">Age</StyledTableCell>
                        <StyledTableCell align="center">Origin</StyledTableCell>
                        <StyledTableCell align="center">Destination</StyledTableCell>
                        <StyledTableCell align="center">Company Name</StyledTableCell>
                        <StyledTableCell align="center">Weight</StyledTableCell>
                        <StyledTableCell align="center">Equipment</StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>
                        <StyledTableCell align="center">Mileage</StyledTableCell>
                        <StyledTableCell align="center">Pickup</StyledTableCell>
                        <StyledTableCell align="center">Provider</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadList.map((row) => (
                        <Row key={row.external_id} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CollapsibleTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: 'transparent',
        color: theme.text,
        borderBottom: `1px solid grey !important`,
        borderCollapse: 'collapse',
        fontSize: 14,
        padding: '12px 4px',
        width: '10%' // Ensure consistent width
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '16px 4px',
        background: 'transparent',
        color: theme.text,
        borderBottom: `1px solid grey !important`,
        borderTop: "none !important",
        fontWeight: 'bold',
        borderColor: theme.secondary,
        width: '10%' // Ensure consistent width
    },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: 'transparent',
    width: '140%',
    color: theme.title,
    '.MuiTabs-indicator': {
        backgroundColor: theme.title,
    }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.text,
    '&.MuiTab-root': {
        fontSize: '.73rem',
        color: theme.text,
    },
    '&&.Mui-selected, &&.Mui-selected:hover': {
        color: theme.title,
        backgroundColor: theme.secondary,
    },
    '&:hover': {
        backgroundColor: theme.hover,
        color: theme.title,
        opacity: 1,
    },
    '&&.Mui-focusVisible': {
        backgroundColor: theme.hover,
        color: theme.title,
    }
}));

const EmailButton = styled(Button)`
    text-transform: none;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 5px;
    background-color: ${props => props.theme.title} !important;
    padding: 8px 24px;
    cursor: pointer;
    color: ${props => props.theme.primary} !important;
    transition: background-color 0.3s, color 0.3s;

    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${props => props.theme.hover};
        color: ${props => props.theme.text};
        cursor: not-allowed;
    }
    &:hover {
        background-color: ${props => props.theme.secondary} !important;
        color: ${props => props.theme.title} !important;
    }
`;

const DialButton = styled(Button)`
    text-transform: none;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.title} !important;
    color: ${props => props.theme.title} !important;
    background-color: ${props => props.theme.hover};
    padding: 8px 24px;
    cursor: not-allowed;
    transition: none;
    opacity: 0.5;
    &:hover, &:focus, &:active {
        background-color: ${props => props.theme.hover};
        color: ${props => props.theme.title} !important;
    }
    pointer-events: none;
`;

const CustomIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
  && img {
    width: 12px;
    height: 12px;
    filter: invert(50%) sepia(23%) saturate(155%) hue-rotate(180deg) brightness(85%) contrast(85%);
    border-radius: 0px;
    margin: 0px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
`;

const BrightSkeleton = styled(Skeleton)`
  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const DetailsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
`;

const DetailFlexColumn = styled(FlexColumn)`
    display: flex;
    min-height: 16rem;
    width: 100%;
    padding: 25px;
    margin-bottom: 1.5rem;
    margin-left: -.5rem;
    margin-top: .5rem;
    background-color: ${props => props.theme.secondary};
    border-radius: 8px;
    border: 1px solid ${props => `${props.theme.detailsText}30`};
`;

const DetailItem = styled.div`
    display: flex;
    align-items: flex-start; /* Ensures top alignment of key and value */
    gap: .5rem;
    margin-bottom: 0.5rem;
`;

const DetailsParagraph = styled(Paragraph)`
    color: ${props => props.theme.detailsText} !important;
    flex: 1; /* Allows the value to take up remaining space */
    word-break: break-word; /* Ensures long text wraps within its container */
`;

const DetailsParagraphHeader = styled(Paragraph)`
    color: ${props => props.theme.title};
    white-space: nowrap; /* Prevents the key from wrapping */
    flex-shrink: 0; /* Prevents the key from shrinking */
`;

const StyledParagraph = styled(Paragraph)`
    color: ${props => props.theme.detailsText} !important;
`;

const MarketPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 16rem;
    width: 100%;
    padding: 25px;
    margin-bottom: 1.5rem;
    margin-left: -.5rem;
    margin-top: .5rem;
    background-color: ${props => props.theme.secondary};
    border-radius: 8px;
    border: 1px solid ${props => `${props.theme.detailsText}30`};
`;

const MarketPriceHeader = styled(Paragraph)`
    font-size: 1rem !important;
    color: ${props => props.theme.detailsText} !important;
`;

const MarketPriceText = styled(Paragraph)`
    color: ${props => props.theme.priceText} !important;
    font-size: 1.3rem !important;
    font-weight: bold  !important;
`;

const StyledTitle = styled(Title)`
    color: ${props => props.theme.text};
`;

const PriceTitle = styled(Title)`
    color: ${props => props.theme.priceText};
`;

const ContentArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    width: 100%;
    position: relative; /* Added to ensure absolute positioning works within this container */
`;

const MapArea = styled.div`
    flex: 1;
    height: 300px;
    width: 50%;
    margin-top: 4.1rem;
    position: absolute; /* Absolutely positioned within the parent container */
    top: 0; 
    left: 50%; 
    right: 0; 
    bottom: 0; 
    z-index: -0;
`;

const MapLoadingArea = styled.div`
    height: 300px;
    width: 100%;
    margin-top: 3.5rem;
    background-color: ${props => props.theme.secondary};
    border-radius: 10px;
`;

const GreenText = styled.span`
    color: ${props => props.theme.priceText};
`;

const RedText = styled.span`
    color: #cc0000;
`;

const MoreInfoButton = styled(Button)`
    text-transform: none;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 5px;
    background-color: ${props => props.theme.title} !important;
    padding: 8px 24px;
    cursor: pointer;
    color: ${props => props.theme.primary} !important;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 1rem;

    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${props => props.theme.hover};
        color: ${props => props.theme.text};
        cursor: not-allowed;
    }
    &:hover {
        background-color: ${props => props.theme.secondary} !important;
        color: ${props => props.theme.title} !important;
    }
`;

const MoreInfoText = styled.span`
    color: #007BFF; /* Bright blue color */
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 1rem;
    display: inline-block;
    transition: color 0.3s ease; /* Smooth transition for color change */
`;

const DetailFlexColumnMoreInfo = styled(FlexColumn)`
    display: flex;
    min-height: 16rem;
    width: 100%;
    padding: 25px;
    margin-bottom: 1.5rem;
    margin-left: -.5rem;
    margin-top: .5rem;
    border-radius: 8px;
`;

const BlurBackground = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    height: 300px;
    margin-top: 4.1rem;
    width: 50%;
    backdrop-filter: blur(5px); /* Blurs the background */
    background-color: ${props => {
        const hex = props.theme.primary.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.85)`;
    }};
    z-index: 1; /* Ensures it appears above other content */
    padding: 1rem;
`;
