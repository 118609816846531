import useAxios from "../useAxios/useAxios";
import {LOGIN_ENDPOINT} from "../../App/Axios/endpoints";

const useAuthenticationApi = () => {
    // axios hook
    const axios = useAxios();

    // login to Django server
    const loginApi = async (email: string, password: string) => {
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            const response = await axios.post(LOGIN_ENDPOINT, formData);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    // const registerApi = async (email: string, password: string) => {
    //     try {
    //         const data = {email: email, password: password};
    //         const response = await axios.post(REGISTER_ENDPOINT, data);
    //         console.log(response);
    //         if (response.status === 200 || response.status === 201) {
    //             return response;
    //         }
    //         return null;
    //     } catch (e) {
    //         console.log(e);
    //         return null;
    //     }
    // };
    //
    // const verifyEmailApi = async (token: string) => {
    //     try {
    //         const config = {params: {token: token}}
    //         const response = await axios.get(VERIFY_EMAIL_ENDPOINT, config);
    //         console.log(response);
    //         return response;
    //     } catch (e) {
    //         console.log(e)
    //         return null;
    //     }
    // }

    return {loginApi};
}


export default useAuthenticationApi;
