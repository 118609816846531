export const lightPalette = {
  primary: '#FFFFFF',
  secondary: '#f4f4f4',
  filterButtons: '#000000',
  text: '#747474',
  detailsText: '#000000',
  alternateText: 'white',
  redBackground: '#C82333',
  sidemenu: '#f4f4f4',
  sideMenuAccent: '#ffffff',
  priceText: '#22902b',
  title: '#171717',
  accent: '#4d4d4d',
  hover: '#dfdfdf',
  arrowIcon: '#000000',
  arrowIconHovered: '#5b5151',
  settingsBorderColor: '#e2d8d8',
  settingsBorderOutlineColor: '#80808080',
  alternativeWhite: '#313030',
  filter:
    'invert(48%) sepia(10%) saturate(270%) hue-rotate(201deg) brightness(95%) contrast(97%)',
  primaryFilter:
    'invert(100%) sepia(11%) saturate(156%) hue-rotate(114deg) brightness(109%) contrast(80%)',
  activeFilter:
    'invert(3%) sepia(2%) saturate(6850%) hue-rotate(218deg) brightness(95%) contrast(83%)',
  accentFilter:
    'invert(39%) sepia(95%) saturate(1748%) hue-rotate(201deg) brightness(100%) contrast(103%)',
  transition: '0.3s ease-in-out all',
  shadowColor: '0px 0px 10px 2px rgba(49, 49, 49, 0.48)',
  borderColor: '1px solid #535353',
};

export const darkPalette = {
  primary: '#000000',
  secondary: '#191919',
  filterButtons: '#AD8449',
  text: '#f4f4f4',
  detailsText: '#C7C7C7',
  alternateText: '#747474',
  redBackground: '#883837',
  sidemenu: '#111111',
  sideMenuAccent: '#1A1A1A',
  priceText: '#8BFFCF',
  title: '#AD8449',
  accent: '#AD8449',
  hover: '#282B32',
  arrowIcon: '#C7C7C7',
  arrowIconHovered: '#AD8449',
  settingsBorderColor: '#2a2727',
  settingsBorderOutlineColor: '#2a2727',
  alternativeWhite: '#f3f2f2',
  filter:
    'invert(50%) sepia(23%) saturate(155%) hue-rotate(180deg) brightness(85%) contrast(85%)',
  primaryFilter:
    'invert(4%) sepia(6%) saturate(2088%) hue-rotate(187deg) brightness(94%) contrast(92%)',
  activeFilter:
    'invert(100%) sepia(26%) saturate(1324%) hue-rotate(309deg) brightness(103%) contrast(110%)',
  inActiveFilter:
    'invert(46%) sepia(11%) saturate(0%) hue-rotate(130deg) brightness(104%) contrast(97%)',
  accentFilter:
    'invert(36%) sepia(12%) saturate(3193%) hue-rotate(216deg) brightness(97%) contrast(94%)',
  transition: '0.3s ease-in-out all',
  shadowColor: '0px 0px 10px 2px rgba(49, 49, 49, 0.48)',
  borderColor: '1px solid #535353',
};
