import React, {createContext, useState} from 'react';
import {ChildrenProps} from "../index";


interface ILoadingContext {
    isLoading: boolean,
    setLoading: (value: boolean) => void
}


const LoadingContext = createContext<ILoadingContext | null>(null);

const LoadingContextProvider = ({children}: ChildrenProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setLoadingCallback = (value: boolean) => {
        setIsLoading(value);
    }

    const contextData = {
        isLoading: isLoading,
        setLoading: setLoadingCallback
    } as ILoadingContext;
    return (
        <LoadingContext.Provider value={contextData}>
            {children}
        </LoadingContext.Provider>
    );
};

export {LoadingContext, LoadingContextProvider};
