import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';

const libraries: ("places" | "drawing" | "geometry")[] = ['places'];

const mapContainerStyle = {
  height: '300px',
  width: '100%',
  borderRadius: '10px',
};

const defaultCenter = {
  lat: 37.0902,
  lng: -95.7129, // Center of the USA
};

interface MyMapProps {
  origin: [number, number];
  destination: [number, number];
  originCity: any;
  originState: string;
  destinationCity: any;
  destinationState: string;
}

const Map: React.FC<MyMapProps> = ({ origin, destination, originCity, originState, destinationCity, destinationState }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCFQGDCwPdftNBe96-r271MV2mZc7TKgBU',
    libraries,
  });

  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const mapRef = useRef<google.maps.Map | null>(null);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const fetchDirections = useCallback((originInput: any, destinationInput: any, fallback = false) => {
    const directionsService = new google.maps.DirectionsService();

    directionsService.route(
      {
        origin: originInput,
        destination: destinationInput,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === 'OK') {
          setDirections(result);
          setMapCenter({ lat: (origin[0] + destination[0]) / 2, lng: (origin[1] + destination[1]) / 2 });
        } else if (!fallback) {
          console.error(`error fetching directions with coordinates ${status}, falling back to city names`);
          fetchDirections(
            `${originCity}, ${originState}`,
            `${destinationCity}, ${destinationState}`,
            true
          );
        } else {
          console.error(`error fetching directions ${status}`);
        }
      }
    );
  }, [origin, destination, originCity, originState, destinationCity, destinationState]);

  useEffect(() => {
    if (isLoaded && origin && destination) {
      fetchDirections(new google.maps.LatLng(origin[0], origin[1]), new google.maps.LatLng(destination[0], destination[1]));
    }
  }, [isLoaded, origin, destination, fetchDirections]);

  const memoizedMapCenter = useMemo(() => mapCenter, [mapCenter]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapContainerStyle}
      zoom={5}
      center={memoizedMapCenter}
      onLoad={onMapLoad}
    >
      <Marker position={{ lat: origin[0], lng: origin[1] }} label={` `} />
      <Marker position={{ lat: destination[0], lng: destination[1] }} label={` `} />
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  );
};

export default Map;
