// Function to get random elements
export const getRandomElements = (list: any[], count: number) => {
    // Create a copy of the original list to avoid modifying it
    const shuffledList = list.slice();

    // Shuffle the list using Fisher-Yates algorithm
    for (let i = shuffledList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }

    // Return the first 'count' elements from the shuffled list
    return shuffledList.slice(0, count);
}
