import { createContext, useEffect, useState } from 'react';
import useChatApi from '../../Hooks/useApi/useChatApi';
import { ChildrenProps } from "../index";

interface IPluginContext {
    LB123Plugin: boolean,
    setLB123Plugin: (value: boolean) => void,
    truckstopPlugin: boolean,
    setTruckstopPlugin: (value: boolean) => void,
}


const PluginContext = createContext<IPluginContext | null>(null);

const PluginContextProvider = ({children}: ChildrenProps) => {
    const { checkTruckstopIntegration } = useChatApi();
    const [LB123Plugin, setLB123Plugin] = useState<boolean>(false);
    const [truckstopPlugin, setTruckstopPlugin] = useState<boolean>(false);


    async function isTruckStopEnabled() {
        const isEnabled = await checkTruckstopIntegration();
        setTruckstopPlugin(isEnabled);
    }

    useEffect(() => {
        isTruckStopEnabled();
    }, [])

    const contextData = {
        LB123Plugin: LB123Plugin,
        setLB123Plugin: (value) => setLB123Plugin(value),
        truckstopPlugin: truckstopPlugin,
        setTruckstopPlugin: (value) => setTruckstopPlugin(value),
    } as IPluginContext;

    return (
        <PluginContext.Provider value={contextData}>
            {children}
        </PluginContext.Provider>
    );
};

export {PluginContext, PluginContextProvider};
