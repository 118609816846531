import React from 'react';
import styled from 'styled-components';
import { HelpBoxType } from "../../App/Types/helpBox";
import { Paragraph, Text } from "../../App/Styles/Typography.styled";

interface HelpBoxProps {
    item: HelpBoxType,
    onClick: () => void
}

const HelpBox: React.FC<HelpBoxProps> = ({ item, onClick }) => {
    return (
        <Container data-testid="help-box-item" onClick={() => onClick()}>
            <HelpParagraph>{item.title}</HelpParagraph>
        </Container>
    );
};

export default HelpBox;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center; /* Center vertically */
    align-items: center;    /* Center horizontally */
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(178, 165, 165, 0.18);
    background-color: ${({ theme }) => theme.primary};
    cursor: pointer;
    transition: ${({ theme }) => theme.transition};
    &:hover {
        background-color: ${({ theme }) => theme.secondary};
    }
`;

const HelpParagraph = styled(Paragraph)`
    color: ${props => props.theme.detailsText};
    text-align: center;  /* Center text horizontally */
`;

const CenteredText = styled(Text)`
    text-align: center;  /* Center text horizontally */
`;
