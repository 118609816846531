import { Outlet } from 'react-router-dom';
import styled from "styled-components";
import LogInPageBackground from '../Assets/loginPageBackground.png';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary';

const DefaultOutlet = () => {
    return (
        <Container>
            <OutletContainer>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </OutletContainer>
        </Container>
    );
};

export default DefaultOutlet;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    background-image:
        linear-gradient(
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.7)
        ),
        url(${LogInPageBackground});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
`;

const OutletContainer = styled.div`
    height: 100vh;
    justify-content: center;
`;
