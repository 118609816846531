import { FC } from "react";
import styled from "styled-components";
import NavigationLoginLayout from "../../Layouts/Navigation/NavigationLogInLayout";
import BottombarLayout from "../../Layouts/Bottombar/BottombarLayout";
import { HeadTitle, Title, Paragraph, Text } from "../../App/Styles/Typography.styled"
import { nameToRgba } from "../../Utils/colorUtils";

const HelpPage: FC = () => {
    return (
        <Container>
            <NavigationLoginLayout/>
                <ContentContainer>
                <PageTitle bold>Guide For First Time Users</PageTitle>

                <StyledTitle bold>Useful Tips:</StyledTitle>
                <List>
                    <ListItem>
                        <StyledParagraph>Start with asking loads from any <Highlight color="green">state or city</Highlight> by mentioning a <Highlight color="dodgerblue">truck type</Highlight> such as “loads from Austin for van trucks” or from “loads from Texas for flatbed”</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>If you don’t mention the <Highlight color="dodgerblue">truck type</Highlight>, the system will ask you to choose the truck type by providing the options</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph> You can ask follow up questions by not mentioning the <Highlight color="green">state or city</Highlight> and the system will provide the results from the same state or city.</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>But when you want to find loads from different states or city, just ask loads from a different state or city</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>The system will only provide answers to <Highlight color="red">price</Highlight>, <Highlight color="deeppink">rate per mile</Highlight>, <Highlight color="darkorchid">load</Highlight>, <Highlight color="dodgerblue">pick up</Highlight>, <Highlight color="orange">origin</Highlight>, <Highlight color="yellow">post age</Highlight>, <Highlight color="red">company</Highlight>, <Highlight color="green">equipment</Highlight>, <Highlight color="dodgerblue">mileage</Highlight> related questions or double, triple or more combinations of the factors. See the below playbook for some sample prompts</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>If you don't specify the number of loads, the system will give 5 loads by default.</StyledParagraph>
                    </ListItem>
                </List>

                <StyledTitle bold>Sample Prompts: <Italic>(Subject to Availability of Data)</Italic></StyledTitle> 
                <Italic>
                    <StyledParagraph fontSize="0.9em">
                    Mention the <Highlight color="dodgerblue">truck type</Highlight> when you initiate a search from any city or state. Otherwise the system will ask you to choose the truck type.
                    </StyledParagraph>
                </Italic>

                <StyledTitle>
                    <Italic>
                        <Highlight color="darkorchid">
                            Basic Prompts:
                        </Highlight>
                    </Italic>
                </StyledTitle>
                <List>
                    <SubTitle>City/Location Origin</SubTitle>
                    <ListItem>
                        <StyledParagraph>Give me loads from Austin for Van trucks</StyledParagraph>
                    </ListItem>
                    <SubTitle>Load Amount</SubTitle>
                    <ListItem>
                        <StyledParagraph>Tell me 23 loads from Atlanta for Step Deck trucks</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Give me 15 loads from Charlotte for flatbed trucks</StyledParagraph>
                    </ListItem>
                    <SubTitle>Truck Type</SubTitle>
                    <ListItem>
                        <StyledParagraph> Loads with flatbed trucks from Austin </StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph> Loads from Phoenix for Van trucks </StyledParagraph>
                    </ListItem>
                    <SubTitle>Price</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Kansas City with higher than $1300 price</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Give 7 Loads from Houston with RPM (or rate per mile) higher than 1.7</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Chicago lower than $1300 price</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Dallas  with RPM (or rate per mile) less than 1.7</StyledParagraph>
                    </ListItem>
                    <SubTitle>Mileage</SubTitle>
                    <ListItem>
                        <StyledParagraph>List of loads from Fort Worth with less than 150 miles</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Jacksonville with transportation distance higher than 250 miles</StyledParagraph>
                    </ListItem>
                    <SubTitle>Pick Up Dates</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from San Antonio with pick up date after 15th of May</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Albany with pick up date before 12th of June</StyledParagraph>
                    </ListItem>
                </List>

                <StyledTitle>
                    <Italic>
                        <Highlight color="green">
                            Combine Two Factors:
                        </Highlight>
                    </Italic>
                </StyledTitle>
                <List>
                    <SubTitle>Age + Origin</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Baltimore posted in last 10 hours</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Buffalo posted in last 12 hours minutes </StyledParagraph>
                    </ListItem>
                    <SubTitle>Age + Destination</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads to Dewitt city from Buffalo posted in last 24 hour</StyledParagraph>
                    </ListItem>
                    <SubTitle>Equipment type</SubTitle>
                    <ListItem>
                        <StyledParagraph>List of loads from Houston posted in last 12 hours for Flatbed</StyledParagraph>
                    </ListItem>
                    <SubTitle>Age + Price</SubTitle>
                    <ListItem>
                        <StyledParagraph>List of loads from Charlotte posted in last 10 hours higher than $1000</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>List of loads from Baltimore posted in last 22 hours lower than $1.5 rate per mile</StyledParagraph>
                    </ListItem>
                    <SubTitle>Age + Mileage</SubTitle>
                    <ListItem>
                        <StyledParagraph>List of loads from Seattle posted in last 21 hours with lower than 1100 miles.</StyledParagraph>
                    </ListItem>
                    <SubTitle>Age + Pickup</SubTitle>
                    <ListItem>
                        <StyledParagraph>Get me loads from Memphis posted in last 30 minutes with a pickup date after 3rd of June</StyledParagraph>
                    </ListItem>
                    <SubTitle>Origin + Equipment type</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Brooklyn for flatbed trucks</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Give me Loads from Austin for Van trucks</StyledParagraph>
                    </ListItem>
                    <SubTitle>Origin + Price</SubTitle>
                    <ListItem>
                        <StyledParagraph>Give me loads priced higher than $350 from Albany, NY</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads priced lower than $12.5 rate per mile from Amarillo</StyledParagraph>
                    </ListItem>
                    <SubTitle>Origin + Mileage</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Kansas city with less than 750 miles</StyledParagraph>
                    </ListItem>
                    <SubTitle>Origin + Pickup date</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Kansas City, MO with pickup date after June3rd</StyledParagraph>
                    </ListItem>
                    <SubTitle>Equipment + Price:</SubTitle>
                    <ListItem>
                        <StyledParagraph>Flatbed truck loads from Austin higher than $1200</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Reefer truck loads from Nashville higher than $1.2 rate per mile</StyledParagraph>
                    </ListItem>
                    <SubTitle>Equipment + Pickup:</SubTitle>
                    <ListItem>
                        <StyledParagraph>Van truck loads from Atlanta with pickup date after 3rd of June</StyledParagraph>
                    </ListItem>
                    <SubTitle>Price + Mileage:</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Memphis  higher than $500 but less than 750 miles</StyledParagraph>
                    </ListItem>
                    <SubTitle>Price + Pickup:</SubTitle>
                    <ListItem>
                        <StyledParagraph>Loads from Boston higher than $500 in price with pickup date after June 5</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Give 12 loads from Phoenix higher than $350 with pickup date after June 5t</StyledParagraph>
                    </ListItem>
                    <SubTitle>Mileage + Pickup:</SubTitle>
                    <ListItem>
                        <StyledParagraph>List of loads from Indianapolis less than a transportation distance of 1800 miles with pickup date after 20th of June</StyledParagraph>
                    </ListItem>
                </List>

                <StyledTitle>
                    <Italic>
                        <Highlight color="dodgerblue">
                            More Complicated Combinations:
                        </Highlight>
                    </Italic>
                </StyledTitle>
                <List>
                    <ListItem>
                        <StyledParagraph>Show me 9 loads from California posted in last 12 hours, higher than 500$, with pick up dates after June 30, rank them with highest rate per mile</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Show me 12 loads from Atlanta posted in last 23 hours with transportation distance higher than 300 miles but lower than $1500 in price</StyledParagraph>
                    </ListItem>
                    <ListItem>
                        <StyledParagraph>Loads from Houston posted in the last 21 hours with cargo weight higher than 15000 lbs, pickup date after 25th June, rank them with highest rate per mile.</StyledParagraph>
                    </ListItem>
                </List>
                </ContentContainer>
            <BottombarLayout/>
        </Container>
    )
}

export default HelpPage;

const Container = styled.div`
    height: 100vh;
`;

const List = styled.ul`
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 2rem;
    color: ${props => props.theme.text};
`
const ListItem = styled.li`
    margin-top: 0.15em;
`
const Italic = styled(Text)`
    font-style: italic;
    color: inherit;
`
const ContentContainer = styled.div`
    width: 75%;
    margin: 0 auto;
`;

const PageTitle = styled(HeadTitle)`
    font-size: 2em;
    text-align: center;
    margin-top: 2rem;
`;

const StyledTitle = styled(Title)`
    font-size: 1.5em;
    margin-top: 2rem
`;

const SubTitle = styled(Title)`
    margin-top: 0.5em;
`

const StyledParagraph = styled(Paragraph)`
    font-size: 1.2em;
`;

const Highlight = styled.span<{ color: string }>`
  background-color: ${({ color }) => nameToRgba(color)};
  padding: 0.2em;
  border-radius: 0.3em;
  color: inherit;
`;

