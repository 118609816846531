import React from 'react';
import {Helmet} from "react-helmet";

type Props = {
    title: string,
};

const HelmetView: React.FC<Props> = ({title}) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default HelmetView;