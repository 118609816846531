import { FC, useState, KeyboardEvent } from "react";
import styled from "styled-components";

import { HiddenButton } from "../../App/Styles/Button.styled";
import { VectorImage } from "../../App/Styles/Image.styled";
import { Paragraph, Text } from "../../App/Styles/Typography.styled";
import { Input } from "../../Pages/Main/Chat/ChatPage.styled";
import { Button } from "../../App/Styles/Button.styled";
import closeSvg from "../../Assets/close.svg"

interface PopupProps {
    title: string,
    subtitle: string,
    hasInput?: boolean | null,
    handleExit: () => void,
    handleSubmit?: (data: string) => void
};

export const Popup: FC<PopupProps> = ({ title, subtitle, handleExit, handleSubmit, hasInput=false }) => {
    const [inputText, setInputText] = useState("");

    const handleClick = () => {
        handleSubmit && handleSubmit(inputText);
        handleExit();
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
       if (event.key === 'Enter') handleClick();
    }

    return(
        <>
        <BackDropMask onClick={handleExit}/>
        <PopupContainer>
            <Banner>
                <Paragraph>{title}</Paragraph>
                <HiddenButton onClick={handleExit}>
                    <VectorImage src={closeSvg}/>
                </HiddenButton>
            </Banner>
            <Text>{subtitle}</Text>
            {hasInput && (<>
                <Input onChange={event => setInputText(event.target.value)} onKeyDown={handleKeyDown}/>
                <Button onClick={handleClick}>
                    <Paragraph>Submit</Paragraph>
                </Button>
            </>)}
        </PopupContainer>
        </>
    )
}

const BackDropMask = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    user-select: none;
    z-index: 1001;
`;

const Banner = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const PopupContainer = styled.div`
    z-index: 1002;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1rem;
    color: ${({theme}) => theme.text};
    transition: ${({theme}) => theme.transition};
    background-color: ${({theme}) => theme.primary};
    border: 1px solid ${({theme}) => theme.secondary};
    border-radius: 10px;
    padding: 2rem;

    /* Center the div in the front of the page */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${VectorImage} {
        filter: ${({theme}) => theme.activeFilter};
    }
`;


