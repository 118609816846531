import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthenticationContext} from '../Context/Auth/AuthContext';


type Props = {
    component: React.ComponentType
    path?: string
}


const PrivateRoute: React.FC<Props> = ({component: RouteComponent, path}) => {
    const authContext = useContext(AuthenticationContext);
    const tokens = authContext?.tokens
    console.log(`Tokens ${tokens}`)
    if (tokens !== null) return <RouteComponent/>
    else {
        return <Navigate to={'/auth'}/>
    }
};

export default PrivateRoute;
