import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../App/Styles/Typography.styled';
import { ILoad } from '../../Models/LoadModel';
import CollapsibleTable from '../Table/CollapsibleTable';
import TypePrompt from '../TypePrompt/TypePrompt';
import Typewriter from 'typewriter-effect';
import { PortalContext } from '../../Context/Portal/PortalContext';

export type MessageItem = {
  isUser: boolean;
  title?: string;
  loadList?: ILoad[];
  promptTruckType?: boolean;
};

type MessageRecyclerProps = {
  messages: MessageItem[];
  handleSelect: (value: string) => void;
};

const MessageRecycler: React.FC<MessageRecyclerProps> = ({
  messages,
  handleSelect,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [typingComplete, setTypingComplete] = useState<boolean>(false);
  const portalContext = useContext(PortalContext);

  const scrollToMessage = useCallback((index: number) => {
    const messageElement = messageRefs.current[index];
    if (messageElement && containerRef.current) {
      const offsetTop = messageElement.offsetTop;
      containerRef.current.scrollTo({
        top: offsetTop - 200,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (typingComplete && containerRef.current) {
      scrollToMessage(messages.length - 1);
    }
  }, [typingComplete]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToMessage(messages.length - 1);
    }
  }, [messages, scrollToMessage]);
  useEffect(() => {
    const handleGlobalScroll = (event: WheelEvent) => {
      if (containerRef.current) {
        const delta = event.deltaY || event.detail;
        containerRef.current.scrollTop += delta;
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleGlobalScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleGlobalScroll);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      {messages.map((item, index) => (
        <MessageRow
          shouldOverflow={portalContext?.isOpen}
          isUser={item.isUser}
          key={index}
          ref={(el) => (messageRefs.current[index] = el)}
        >
          {item.title && (
            <QueryContainer isUser={item.isUser}>
              {item.isUser ? (
                <Paragraph>{item.title}</Paragraph>
              ) : (
                <Paragraph as="div" className="typewriter-container">
                  <Typewriter
                    options={{
                      delay: 5, // Faster typing speed
                      cursor: '', // Removes the blinking cursor
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(item.title || '')
                        .callFunction(() => setTypingComplete(true))
                        .start();
                    }}
                  />
                </Paragraph>
              )}
            </QueryContainer>
          )}
          {item.loadList && item.loadList.length > 0 && (
            <TableMessageContainer>
              <CollapsibleTable loadList={item.loadList} />
            </TableMessageContainer>
          )}
          {item.promptTruckType &&
            typingComplete &&
            messages.length - 1 === index && (
              <TruckPromptContainer isUser={item.isUser}>
                <TypePrompt
                  handleSelect={handleSelect}
                  handleToggle={() => scrollToMessage(messages.length - 1)}
                />
              </TruckPromptContainer>
            )}
        </MessageRow>
      ))}
    </Container>
  );
};

export default MessageRecycler;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-row: 1 / span 7;
`;

export const MessageRow = styled.div<{
  isUser: boolean;
  shouldOverflow: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;

  /*  overflow-y allows the background to stop being scrollable when modal is Open */
  overflow-y: ${({ shouldOverflow }) => (shouldOverflow ? 'hidden' : 'none')};

  align-items: ${({ isUser }) => (isUser ? 'end' : 'start')};
  gap: 10px;
  width: 100%;
  border-radius: 10px;
`;

const TableMessageContainer = styled.div`
  border-radius: 20px;
  background-image: ${(props) =>
    `linear-gradient(to bottom right, ${props.theme.secondary}, ${props.theme.primary})`};
  padding: 10px 10px 10px 10px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-left: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-right: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-bottom: none;

  ${Paragraph} {
    color: ${(props) => props.theme.title};
    font-size: 12px;
    font-weight: 500;
  }
`;

const QueryContainer = styled.div<{ isUser?: boolean | undefined }>`
  padding: 0.75rem;
  background-color: ${(props) => props.theme.secondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: ${(props) =>
    props.isUser ? '0.5rem 0px 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0.5rem'};
  margin: 0;

  .typewriter-container {
    color: ${(props) => props.theme.text};
  }
`;

const TruckPromptContainer = styled(QueryContainer)`
  flex-direction: inline;
  position: relative;
`;
