import useAxios from "../useAxios/useAxios";
import {
    LB123_ADD_TOKENS_ENDPOINT,
    LB123_DETAILS_ENDPOINT,
    LB123_LOADS_ENDPOINT,
    LB123_LOGIN_ENDPOINT,
    FMCSA_DETAILS_ENDPOINT,
    TRUCKSTOP_DETAILS_ENDPOINT,
} from "../../App/Axios/endpoints";

const useLoadsApi = () => {
    // axios hook
    const axios = useAxios();
    const authAxios = useAxios(true);

    // login to Django server
    const loginLB123Api = async () => {
        try {
            const response = await axios.post(LB123_LOGIN_ENDPOINT);
            if (response.status === 200 || response.status === 201) {
                return response.data;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    // Seemingly unused hook - Aniketh
    const addLB123TokensApi = async (tokenCode: string) => {
        try {
            const url = `${LB123_ADD_TOKENS_ENDPOINT}?code=${tokenCode}`
            const response = await authAxios.get(url);
            if (response.status === 200 || response.status === 201) {
                console.log(response)
                return response.data;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    const getLB123LoadsApi = async (query: string) => {
        try {
            const response = await authAxios.get(`${LB123_LOADS_ENDPOINT}?query=${query}`);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    const getLB123DetailsAPI = async (load_id: string) => {
        try {
            const response = await authAxios.get(`${LB123_DETAILS_ENDPOINT}?load_id=${load_id}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (e: any) {
            if (e && e.response && e.response.data) {
                return e!.response?.data.data ? e.response.data.data.detail : null;
            }
            return null;
        }
    }

    const getTruckstopDetailsAPI = async (load_id: string) => {
        try {
            const response = await authAxios.get(`${TRUCKSTOP_DETAILS_ENDPOINT}?load_id=${load_id}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (e: any) {
            if (e && e.response && e.response.data) {
                return e!.response?.data.data ? e.response.data.data.detail : null;
            }
            return null;
        }
    }

    const getFMCSADetailsAPI = async (number: string | null, isDot: boolean) => {
        try {
            if (number === null){
                console.log("Carrier Number is null")
                return null;
            }
            const url = !isDot ? `${FMCSA_DETAILS_ENDPOINT}?mc_number=${number}` : `${FMCSA_DETAILS_ENDPOINT}?dot_number=${number}`
            const response = await authAxios.get(url);
            if (response.status === 200) {
                return response.data;
            }
        } catch (e: any) {
            if (e && e.response && e.response.data) {
                return e!.response?.data.data ? e.response.data.data.detail : null;
            }
            return null;
        }
    }

    return {
        loginLB123Api,
        getLB123LoadsApi,
        addLB123TokensApi,
        getLB123DetailsAPI,
        getFMCSADetailsAPI,
        getTruckstopDetailsAPI
    };
}


export default useLoadsApi;
