import React, {useContext, useEffect, useRef, useState} from 'react';
import {createPortal} from "react-dom";
import styled from "styled-components";
import { PortalContext } from '../../Context/Portal/PortalContext';

type Props = {
    children: JSX.Element,
    onClose: () => void
};

const PortalLayout: React.FC<Props> = ({children, onClose}) => {
    // contexts
    const portalContext = useContext(PortalContext);
    // states
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // refs
    const modalRef = useRef(null);
    // effects
    useEffect(() => {
        setIsOpen(prevState => portalContext?.isOpen!)
    }, [portalContext?.isOpen]);
    // listeners
    const closeModal = (event: React.MouseEvent) => {
        if (event.target === modalRef.current) {
            setIsOpen(false);
            onClose();
        }
    };
    if (!isOpen) {
        return null;
    }
    return createPortal(
        <Overlay ref={modalRef} open={isOpen} onClick={(event) => closeModal(event)}>
            {children}
        </Overlay>,
        document.body
    )
};

export default PortalLayout;


const Overlay = styled.div<{open: boolean}>`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 20px;
    opacity: ${(props) => (props.open ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
`;