import { KeyboardEvent } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Image } from '../../../App/Styles/Image.styled';

import { Input, InputContainer, SendButton } from './ChatPage.styled';

interface ChatPageInputProps {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => Promise<void>;
  inputText: string;
  setInputText: (value: React.SetStateAction<string>) => void;
  isProgress: boolean;
  onSendMessageClickListener: (query?: string) => Promise<void>;
}

export const ChatPageInput: React.FC<ChatPageInputProps> = ({
  inputRef,
  handleKeyDown,
  inputText,
  setInputText,
  isProgress,
  onSendMessageClickListener,
}) => {
  return (
    <InputContainer>
      <Input
        type="text"
        spellCheck={false}
        ref={inputRef}
        placeholder="Type your query here..."
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => setInputText(() => event.target.value)}
      />
      {isProgress ? (
        <CircularProgress color="warning" />
      ) : (
        <SendButton
          onClick={() => onSendMessageClickListener()}
          disabled={!inputText}
        >
          <Image
            src="https://www.svgrepo.com/show/520931/send-2.svg"
            width="1.5em"
            height="1.5em"
          />
        </SendButton>
      )}
    </InputContainer>
  );
};
