import React from 'react';
import {AuthenticationContextProvider} from '../Auth/AuthContext';
import {ChildrenProps} from "../index";
import {ThemeContextProvider} from "../Theme/ThemeContext";
import {LoadingContextProvider} from "../Loading/LoadingContext";
import {PluginContextProvider} from "../Plugin/PluginContext";
import {PortalContextProvider} from "../Portal/PortalContext";

const GlobalContext: React.FC<ChildrenProps> = ({children}) => {
    return (
        <ThemeContextProvider>
            <PortalContextProvider>
                <LoadingContextProvider>
                    <AuthenticationContextProvider>
                        <PluginContextProvider>
                            {children}
                        </PluginContextProvider>
                    </AuthenticationContextProvider>
                </LoadingContextProvider>
            </PortalContextProvider>
        </ThemeContextProvider>
    );
};

export default GlobalContext;
