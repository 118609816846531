// Utility function to convert hex to rgba with transparency
export const hexToRgba = (hex: string, alpha: number = 0.3) => {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Utility function to convert named CSS color to rgba using canvas (for modern browsers)
export const nameToRgba = (name: string, alpha: number = 0.3) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = name;
    const color = ctx.fillStyle;
    return hexToRgba(color, alpha);
  }
  return "rgba(0, 0, 0, 0)"
};
